<template>
    <div class="box-body">
        <ul class="timeline timeline-inverse" v-for="call_item in client_calls">
            <li class="time-label">
                <span class="bg-blue">
                    {{ call_item.start_date }}
                </span>
            </li>
            <li>
                <div class="timeline-item">
                    <h3 class="timeline-header" style="word-break: break-all;"
                        :style="'background:' + '#f4f4ff'">
                        <strong style="font-weight: 600;">
                            {{ call_item.call_type }}
                        </strong>
                    </h3>
                    <div class="timeline-body">
                        <p class="mb-1">
                            Длительность : {{ call_item.billsec }} секунд.
                        </p>
                        <div v-if="call_item.callLink">
                            <audio controls ref="audio" :src="call_item.callLink" />
                        </div>
                        <p class="font-small">
                            <span class="pull-right">
                                <span v-if="call_item.employee">
                                    <strong>{{ call_item.employee.name }}</strong> (ID {{ call_item.employee.id }})
                                </span>
                                <span v-else>
                                    <strong>Номер менеджера : {{ call_item.internalNumber }} </strong>
                                </span>
                            </span>
                        </p>
                        <br/>
                    </div>
                </div>
            </li>
            <li>
                <i class="fa fa-clock-o bg-gray"></i>
            </li>
        </ul>
        <div class="panel panel-default" v-if="client_calls.length === 0">
            <div class="panel-body text-center text-muted mt-5 mb-5">
                Нет элементов для отображения
            </div>
        </div>
    </div>
</template>
  
<script>
    export default {
        name: 'client-calls',
    
        props: {
            clientId: {
                type: [String, Number],
                required: true,
            },
        },
    
        data: () => ({
            client_calls: [],
        }),
    
        async created() {
            try {
                const client_calls_response = await API.apiClient.get(
                    '/client/' + this.clientId + '/calls'
                );
        
                this.client_calls = client_calls_response.data;
            } catch (error) {
                errorHandler(error);
            }
        }
    }
</script>
  
<style scoped>
  
</style>